import styled from 'styled-components';
import Ellipse from 'src/shared/assets/images/Ellipse.png';
import EllipseBottomMedium from 'src/shared/assets/images/EllipseBottomMedium.png';
import { breakpoint } from 'src/shared/styles/variables';

export const ContainerCards = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 17px;
    ${breakpoint('lg')`
        flex-direction: row-reverse;
        margin-top: 50px;
    `}
    
`;
export const ContentCardLogin = styled.div`
    align-items: center;
    background: ${(props) => props.theme.colors.backgrounds.gray450};
    border: 0.5px solid ${(props) => props.theme.colors.disabled.gray};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    height: 269px;
    justify-content: center;
    margin-top: 12px;
    padding: 0px 20px;
    text-align: center;
    p {
        font-family: ${(props) => props.theme.fonts.mon_regular};
        font-size: 14px;
        line-height: 19.2px;
        margin: 0 auto;
        max-width: 172px;
        padding: 17.86px 0px 18px;
    }
    button {
        font-size:18px;
        line-height: 21.6px;
        padding: 13px 36.5px;
    }
    img {
        height: 84px;
        width: 84px;
    }
    ${breakpoint('lg')`
        background: ${(props) => props.theme.colors.backgrounds.gray500};;
        background-repeat: no-repeat; 
        background-size: cover;
        background-image: url(${Ellipse});
        border: 0.5px solid ${(props) => props.theme.colors.backgrounds.gray400};;
        height: 391px;
        margin-right:14px;
        margin-top: 0px;
        width: 294px;
        min-width: 294px;
        img{
        height:128px;
        width:128px; 
        }
        p{
        max-width: 172px;
        padding: 24px 0px 22px;
        }
        button {
            border-radius: 12px;
            font-size:14px;
            line-height: 16px;
            padding: 8px 32px;
        }
    `}
`;
export const ContentCardsRegister = styled.div`
    background: ${(props) => props.theme.colors.backgrounds.gray450};
    border: 0.5px solid ${(props) => props.theme.colors.disabled.gray};
    border-radius: 16px;
    padding: 32px 0px 26px;
    position: relative;
    width: 100%;

    button{
        margin: 0 auto;
        font-size: 18px;
        line-height: 21.6px;
        padding: 13px 40px;
        z-index:5;
    }
    ${breakpoint('lg')`
        background: ${(props) => props.theme.colors.backgrounds.gray500};;
        background-image: url(${EllipseBottomMedium});
        background-repeat: no-repeat; 
        background-size: cover;
        border: 0.5px solid ${(props) => props.theme.colors.backgrounds.gray400};;
        max-width: 871px;
        margin-bottom: 32px;
        button{
            bottom:0;
            border-radius: 12px;
            font-size:14px;
            left: 50%; 
            line-height: 16px;
            margin-bottom:30px ;
            padding: 8px 32px;
            position:absolute;
            transform: translate(-50%);
        }
    `}
`;
export const ContainerTitleRegister = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
        margin-bottom: 16px;
        margin-top: 9px;
        max-width: 300px;
    }
    ${breakpoint('lg')`
        p {
            margin-bottom: 0px;
            margin-top: 16px;
            max-width:522px;
        }
        button {
            border-radius: 12px;
            font-size:14px;
            line-height: 16px;
            padding: 8px 32px;
        }
    `}
`;
export const TitleCards = styled.h2`
    color: ${(props) => props.theme.colors.accents.base.yellow};
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 20px;
    letter-spacing: 0.02em;
    margin: 0 auto;
    width: 100%;
`;
export const TitleRegister = styled.h1`
    align-items: center;
    color: ${(props) => props.theme.colors.accents.base.yellow};
    display: flex;
    font-family: ${(props) => props.theme.fonts.mon_bold};
    font-size: 20px;
    float: left;
    letter-spacing: 0.02em;
    margin: 0 auto;
    text-align: center;
    span{
        color: ${(props) => props.theme.colors.accents.base.yellow};
        margin-left: 5px;
    }
    ${breakpoint('lg')`
        ::before, ::after{
        content:'';
        width: 228px;
        height: 0px;
        flex:1;
        border: 1px solid ${(props) => props.theme.colors.backgrounds.gray400};
        border-block-start:none;
        }
        ::before{
            margin-right: 19px;
        }
        ::after{
            margin-left: 19px;
        }
        span{
            color: white;
        }`
    }
`;

export const TextCards = styled.p`
    font-family: ${(props) => props.theme.fonts.lf_regular};
    font-size: 14px;
    line-height: 18px;
    text-align: center;
  
`;
export const Cards = styled.div`
    width: 240px;
    margin:0 auto;

    .slick-slide {
        margin: 32px 0px 31px;
    }
    .slick-dots {
        margin: 21px 0px 16px;
    }
    .slick-list {
        margin: 0;
        text-align: center;
    }
    
    .slick-dots li button:before {
        color:${(props) => props.theme.colors.backgrounds.gray400};
        font-size: 14px;
    }
    .slick-dots li.slick-active button:before {
        color: ${(props) => props.theme.colors.alerts.green};
        text-shadow: 0px 0px 8px rgba(0, 212, 85, 0.5);
    }
    ${breakpoint('lg')`
        width: 100%;
        .slick-track{
            margin-bottom:32px;
            width:auto !important;
            display:flex;
            justify-content:center;
        }
        .slick-track .slick-slide{
            width:auto !important;
            
            margin-top:25px;
        }
    `}
    ${breakpoint('md', 'lg')`
            margin:0 auto;
            padding:17px;
            width:500px;
            
    `}
`;
export const CardRegister = styled.div`
    align-items: center;
    background-color: ${(props) => props.theme.colors.backgrounds.gray500};
    border: 1px solid ${(props) => props.theme.colors.backgrounds.gray400};
    border-radius: 16px;
    display: flex;
    justify-content: center;
    margin-left:8px;
    margin-right:8px;
    max-width: 217px;
    min-height: 293px;
    min-width: 217px;
    text-align: center;
    
    img {
        height: 72px;
        margin: 0 auto;
        width: 72px;
    }
    p {
        text-align: center;
        padding:0px  24px;
    }
    div {
        margin: 16px 0px 16px;
    }
    ${breakpoint('lg')`
        border: 0.75px solid ${(props) => props.theme.colors.disabled.gray};
        max-width: 240px;
        min-height: 324px;
    `}
`;
