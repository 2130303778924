import React from 'react';
import { Button, Layout, Redirect } from '@componentsShared';
import { graphql, useStaticQuery } from 'gatsby';
import Slider from 'react-slick';
import Viewport from 'src/shared/hooks/viewport';
import {
    CardRegister,
    Cards,
    ContainerCards,
    ContainerTitleRegister,
    ContentCardLogin,
    ContentCardsRegister,
    TextCards,
    TitleCards,
    TitleRegister
} from './styled';
import { IProps } from './types';
import { colorText } from '@utils/colorText';


const ContainerRegister = ({ page }: IProps) => {
    const viewport = Viewport();
    const cardsRegister = useStaticQuery(graphql`
        query cardsRegister {
            allContentfulCardsDelCarruselDeRegistro {
                nodes {
                    card {
                        image {
                            file {
                                url
                            }
                            description
                        }
                        title
                        text
                        id
                    }
                }
            }
            allContentfulCard(filter: { title: { eq: "Iniciar sesión" } }) {
                nodes {
                    title
                    text
                    image {
                        file {
                            url
                        }
                        description
                    }
                }
            }
        }
    `);
    const {
        allContentfulCardsDelCarruselDeRegistro: {
            nodes: [cards]
        }
    } = cardsRegister;
    const {
        allContentfulCard: {
            nodes: [card]
        }
    } = cardsRegister;
    const {
        title,
        text,
        image: {
            file: { url },
            description
        }
    } = card;
    const settings = {
        dots: true,
        infinite: true,
        arrows: false,
        slidesToShow: viewport.desktop ? 3 : viewport.tablet ? 2 : 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000
    };

    return (
        <ContainerCards>
            <ContentCardsRegister>
                <ContainerTitleRegister>
                    <TitleRegister
                        dangerouslySetInnerHTML={{ __html: colorText(page.title) }}
                    />
                    <TextCards>
                        ¡Hoy es tu día de suerte!, déjanos tus datos y aunque no te definen como
                        persona, los necesitamos para darte un servicio confiable.
                    </TextCards>
                </ContainerTitleRegister>
                <Redirect url="registro/datos-personales/">
                    <Button text="Regístrate" />
                </Redirect>
                <Cards>
                    <Slider {...settings}>
                        {cards.card.map((card: any) => (
                            <div>
                                <CardRegister key={card.id}>
                                    <div>
                                        <img
                                            src={card.image.file.url}
                                            alt={card.image.description}
                                        />
                                        <TitleCards>{card.title}</TitleCards>
                                        <TextCards>{card.text}</TextCards>
                                    </div>
                                </CardRegister>
                            </div>
                        ))}
                    </Slider>
                </Cards>
            </ContentCardsRegister>
            <ContentCardLogin>
                <img src={url} alt={description} />
                <p>{text}</p>
                <Redirect url="iniciar-sesion/">
                    <Button text={title} typeButton="bottomless"></Button>
                </Redirect>
            </ContentCardLogin>
        </ContainerCards>
    );
};

export default ContainerRegister;
