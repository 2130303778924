import React from 'react';
import { Breadcrumbs, ButtonGoBack, Layout, SEO } from '@componentsShared';
import ContainerRegister from 'src/components/organisms/containerRegister';
import { WrapperRegister } from './styled';
import { graphql, useStaticQuery } from 'gatsby';
import Viewport from 'src/shared/hooks/viewport';
import { getMetadataPage } from '@utils/metadata';

const Register = () => {
    const viewport = Viewport();

    let crumbs = [
        {
            crumbLabel: 'Inicio',
            pathname: '/'
        },
        {
            crumbLabel: 'Registro',
            pathname: 'registro/'
        }
    ];

    const metadata = useStaticQuery(graphql`
        query dataRegister {
            allContentfulPaginaInternaFooter(filter: { page: { eq: "Registro" } }) {
                nodes {
                    title
                    slug
                    metaData {
                        title
                        descripcion
                        keyWords
                        image {
                            file {
                                url
                            }
                        }
                    }
                }
            }
        }
    `);

    const {
        allContentfulPaginaInternaFooter: {
            nodes: [metaData]
        }
    } = metadata;

    return (
        <Layout>
            <SEO {...getMetadataPage(metaData?.metaData, metaData.slug || '/registro/')} />
            <WrapperRegister>
                {viewport.desktop ? <Breadcrumbs crumbs={crumbs} /> : <ButtonGoBack />}

                <ContainerRegister page={metaData}></ContainerRegister>
            </WrapperRegister>
        </Layout>
    );
};

export default Register;
