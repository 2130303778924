import { breakpoint } from "src/shared/styles/variables";
import styled from "styled-components";

export const WrapperRegister = styled.div`
    width: 100%;
    padding-top:30px;

    & > div:first-child {
        padding: 0 0 20px 17px;
    }

    ${breakpoint('md')`
        & > ul:first-child {
            margin-left: 17px;
        }
    `}
`;
